<template>
    <div class="page-home">
        <div class="banner">
          <van-swipe class="banner-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item,index) in banner" :key="index">
              <a :href="item.link"  target="_blank"><img class="banner-img" :src="item.image" alt=""></a>
            </van-swipe-item>
          </van-swipe>
          <div class="slide-mask"></div>
        </div>
        <div class="main-wrap">
            <div class="menu">
              <div class="btn-menu01 btn-menu">
                <a href="/mapHome">等级景区</a>
              </div>
              <div class="btn-menu02 btn-menu" @click="toMap(1)">
                <a href="javascript:;">专题地图</a>
              </div>
              <div class="btn-menu03 btn-menu" @click="toMap(2)">
                <a href="javascript:;">线路推荐</a>
              </div>
              <div class="btn-menu04 btn-menu" @click="toMap(3)">
                <a href="javascript:;">公共设施</a>
              </div>
            </div>
            <div class="title">
              <h3>精彩北京 畅游京城</h3>
            </div>
            <div class="tabs">
              <van-tabs class="tabs-wrap" v-model="tabActive" swipe-threshold="4" type="card" sticky animated @click="onClickTab">
                <van-tab v-for="(item,index) in tabs" :key="index" :title="item.name">
                  <div class="lists">
                    <div class="item-list" v-for="(itemList,listIndex) in list" :key="listIndex">
                      <a :href="itemList.link" target="_blank">
                        <div class="pic-wrap">
                          <img :src="itemList.image" alt="">
                        </div>
                        <h4 class="item-title"><a :href="itemList.link">{{itemList.title}}</a></h4>
                      </a>
                    </div>
                  </div>
                </van-tab>
              </van-tabs>
            </div>
        </div>
    </div>
  </template>
<style>
    .page-home .banner{height: 6.7rem;width: 100%;position: relative;}
    .page-home .banner-swipe{height: 6.7rem;}
    .page-home .banner-swipe .banner-img {width: 100%;height: 100%;}
    .slide-mask{position: absolute;width: 100%;height: 1.2rem;bottom: 0;background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));}
    .page-home .van-swipe__indicator {
      width: .13rem!important;height: .13rem!important;
    }
    .page-home .van-swipe__indicator.van-swipe__indicator--active{}
    .page-home .van-swipe__indicators{bottom: 1rem;}
    .page-home .main-wrap{margin:  0 .18rem;background-color: #fff;border-radius: .16rem .16rem 0 0;padding-top: .25rem;position: relative;margin-top: -.8rem;width: 100%;width: 7.18rem;}
    .page-home .title{margin-top: .35rem;}
    .page-home .title h3{font-size: .34rem;font-weight: bold;}
    .page-home .tabs{margin-top: .24rem;}
    .page-home .btn-tab{width: 1.47rem;height: .74rem;border-radius: .24rem;margin-right: .12rem;background-color: #58beff;color: #fff;font-size: .27rem;text-align: center;line-height: .74rem;}
    .page-home .van-tab{width: 1.47rem;height: .74rem;border-radius: .24rem;margin-right: .12rem;background-color: #f6f6f6;color: #5c5c5c;font-size: .27rem;text-align: center;line-height: .74rem;white-space: nowrap;}
    .menu{display: flex;justify-content: space-around;align-items: center;}
    .page-home .menu .btn-menu{width: 1.29rem;height: 1.49rem;background-image: url(~@/assets/images/home_tab_icons.png);background-size: 7.16rem 1.2rem;background-repeat: no-repeat;box-sizing: border-box;text-align: center;}
    .btn-menu a{font-size: .24rem;color: #0b0b0b;font-weight: bold;display: block;padding-top: 1.1rem;}
    .btn-menu a:visited{font-size: .24rem;color: #0b0b0b;font-weight: bold;}
    .btn-menu a:hover{font-size: .24rem;color: #0b0b0b;font-weight: bold;}
    .page-home .menu .btn-menu01{background-position: -.23rem -0.1rem;}
    .page-home .menu .btn-menu02{background-position: -2.03rem -0.1rem;}
    .page-home .menu .btn-menu03{background-position: -3.84rem -0.1rem;}
    .page-home .menu .btn-menu04{background-position: -5.66rem -0.1rem;}
    /*图文列表 */
    .lists{display: flex;justify-content: space-between;align-items: flex-start;flex-wrap: wrap;margin-top: .36rem;}
    .item-list{margin-bottom: .24rem;width: 3.44rem;}
    .item-title{margin-top: .2rem;min-height: .64rem;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2; /* 设置为想要的行数 */overflow: hidden;text-overflow: ellipsis;}
    .item-title a{color: #333;font-size: .28rem;line-height: .32rem;}
    .item-title a:visited{text-decoration: none;color: #333;}
    .item-title a:hover{text-decoration: none;color: #333;}

    .pic-wrap{width: 3.44rem;height: 4rem;border-radius: .16rem;overflow: hidden;display: flex;justify-content: center;}
    .pic-wrap img{flex: 1;}
    /*tab 样式覆盖*/
    .van-tabs--card>.van-tabs__wrap{height: .74rem;}
    .van-tabs__wrap--scrollable .van-tabs__nav--complete{padding: 0;}
    .van-tabs__nav--card{height: .74rem;margin: 0;border: 0px solid #ee0a24;}
    .van-tabs__nav--card .van-tab{color: #5c5c5c;border-right: 0px solid #ee0a24;}
    .van-tabs__nav--card .van-tab.van-tab--active{background-color: #58beff;}
</style>
<script>
import Vue from 'vue'
import { Swipe, SwipeItem, Tab, Tabs, List } from 'vant'
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(List)
import { getInfoFocus } from '@/api/info.js'
export default {
  name: 'HomePage',
  data() {
    return {
      banner: [],
      tabs: [
        // 列表tab 当季推荐 乐享民宿 博物探秘 红色旅游 品读建筑 漫步北京
        {
          name: '当季推荐',
          type: '5'
        },
        {
          name: '乐享民宿',
          type: '6'
        },
        {
          name: '博物探秘',
          type: '7'
        },
        {
          name: '红色旅游',
          type: '8'
        },
        {
          name: '漫步北京',
          type: '10'
        }
      ],
      current: 0,
      // banner序号
      tabActive: 0,
      // 当前tab序号
      list: [
        {
          link: 'https://mp.weixin.qq.com/s/SSGe0-7o7KudvdQIEm-wUA',
          image: 'https://mmbiz.qpic.cn/mmbiz_png/qlcFYxkP2eERXH3TiaOdug5oTxMgaFR3mx1YUiccHzficUuDiaCbUBaZgmZ9ib1Uozum0UAr8qLEFI8OxPKWBfe0QQg/640',
          title: '穿行山水间，赏如画美景'
        }
      ],
      loading: false,
      finished: false
    }
  },
  methods: {
    toMap(index) {
      // var query = { tabIndex: index }
      // this.$router.push({ path: '/mapHome', query: query })
      // this.$router.push({ path: `/mapHome?tabIndex=${index}` })
      location.href = '/mapHome?tabIndex=' + index
    },
    onChange(index) {
    // banner切换
      this.current = index
    },
    onClickTab(name, title) {
      console.log(this.tabActive)
      var _type = this.tabs[this.tabActive].type
      this.getTagList(_type)
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      console.log(this.tabActive)
    },
    async getStartImg() {
      const param = {
        type: 4
      }
      const res = await getInfoFocus(param)
      if (res.data.length !== 0) {
        this.banner = res.data[0].images
      }
    },
    async getTagList(type) {
      const param = {
        type: type
      }
      const res = await getInfoFocus(param)
      if (res.data.length !== 0) {
        this.list = res.data[0].images
      }
    }
  },
  created() {
    this.getStartImg()
    var _type = this.tabs[this.tabActive].type
    this.getTagList(_type)
    this.getTagList(_type)
  }
}
</script>
